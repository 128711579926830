import "./styles.scss";

import React from "react";

import Button from "components/Button";

const mainClass = "info-page__content";

const Content = ({ icon, title, text }) => {
  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__left`}>
          <div className={`${mainClass}__left__text`}>
            <h1>{title}</h1>
            <h5>{text}</h5>
          </div>
          <Button variant="pink" href="/">
            powrót do strony głównej
          </Button>
        </div>
        {icon}
      </div>
    </div>
  );
};

export default Content;
