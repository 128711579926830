import React from "react";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";
import Content from "page_components/info_page/Content";

import Logo from "icons/big-logo.svg";

const NotFoundPage = () => {
  return (
    <Layout hide_contact_section>
      <Content icon={<Logo />} title="Błąd 404" text="Ta strona nie istnieje" />
    </Layout>
  );
};

export const Head = () => <Seo title="Błąd 404 | Kameralna Dolina" />;

export default NotFoundPage;
